<template>
    <router-view />
</template>

<script>
// import "@/assets/scss/maslow/main.scss"
import "@/assets/scss/main.scss"

export default {
  title: "Maslow"
}
</script>